.has-many-view {
    margin-left: -@modal-inner-padding * 1.5;
    margin-right: -@modal-inner-padding;

    > .error-container {
        margin-left: @modal-inner-padding * 1.5;
        margin-right: @modal-inner-padding;
        margin-bottom: 10px;
    }

    > .header {
        position: relative;
        margin-left: @modal-inner-padding * 1.5;
        font-weight: bold;
        font-size: @font-size-large;
    }
}

.has-many-item-view {
    padding: 10px @modal-inner-padding;
    padding-left: @modal-inner-padding * 1.5;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);

    &:nth-child(odd) {
        background: rgba(0, 0, 0, 0.07);
    }
}