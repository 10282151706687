@vendor: "../../../../../node_modules";
@twbs: "@{vendor}/bootstrap-less/bootstrap/";

@import "variables";

@import "bootstrap";
@import (inline) "@{vendor}/fancybox/dist/css/jquery.fancybox.css";

/* ==========================================================================
   Loading custom webfonts
   ========================================================================== */

@font-face {
  font-family: "SofiaPro";
  src: url("/app/fonts/sofia_pro_regular-webfont.woff2") format("woff2"), url("/app/fonts/sofia_pro_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SofiaProLight";
  src: url("/app/fonts/sofia_pro_light-webfont.woff2") format("woff2"), url("/app/fonts/sofia_pro_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SofiaProMedium";
  src: url("/app/fonts/sofia_pro_medium-webfont.woff2") format("woff2"), url("/app/fonts/sofia_pro_medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SofiaProBold";
  src: url("/app/fonts/sofia_pro_bold-webfont.woff2") format("woff2"), url("/app/fonts/sofia_pro_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* ==========================================================================
   CRUDDY APPLICATION STYLES
   ========================================================================== */

html, body {
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body.loading:before {
  content: '';
  position: absolute;

  .size(100%, @navbar-height);
  top: 0;
  left: 0;

  z-index: @zindex-navbar-fixed + 1;

  background: url("../img/loader-bars.gif") center center no-repeat rgba(255, 255, 255, 0.3);
}

.cruddy-global-error {
  display: none;
  position: fixed;
  margin: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal + 1;
  border-radius: 0;
  border-top-right-radius: @border-radius-large;
  font-size: @font-size-large;
  max-width: 600px;

  > .data {
    margin-right: 5px;
  }

  > .close {
    font-size: 21px;
  }
}

@import "layout";
@import "entity-page";
@import "inputs";
@import "form";
@import "datagrid";

/* ==========================================================================
   PM Style overrides
   ========================================================================== */
@import "atoms/buttons";
@import "molecules/nav-tabs";
@import "molecules/super-search";
@import "molecules/alert";

.container {
  @media (min-width: @screen-sm-min) {
    width: auto;
  }
  @media (min-width: @screen-md-min) {
    width: auto;
  }
  @media (min-width: @screen-lg-min) {
    width: auto;
    max-width: 1600px;
  }
}

.navbar {
  margin-bottom:0;
}

.navbar-default {
  font-family: @font-family-base-medium;
  font-size: 13px;
  line-height: (18 / 13);
}

.navbar-collapse {
  > .navbar-nav > li a {
    @media (max-width: @grid-float-breakpoint) {
      font-size: 13px;
      line-height:18px;
      padding:5px 10px;
    }
  }
  > .navbar-nav > li.open > .dropdown-menu a {
    @media (max-width: @grid-float-breakpoint) {
      padding:5px 40px;
    }
  }
}

.entity-form {
  height: auto;
  min-height: 100%;
  position: absolute;
  transition: none;

  > .navbar {
    position: relative;
  }
  > .tab-content {
    padding-top: 0;
  }
}

.navbar-sidebar {
  background-color: #009850;
  border-color: #00BD64;

  .navbar-nav {

    @media (max-width: @grid-float-breakpoint) {
      margin-left:0;
    }

    > li > a {
      &:hover,
      &:focus {
        color: #DBD1E0;
      }
      @media (max-width: @grid-float-breakpoint) {
        font-size: 13px;
        line-height:18px;
        padding:5px 10px;
      }
    }

    > .active > a {
      &,
      &:hover,
      &:focus {
        background-color: #00BD64;
      }
    }
  }
}

.navbar-nav > li > a {
  line-height: 38px;
  padding-top: 22px;
  padding-bottom: 12px;
}

// The dropdown menu (ul)
.dropdown-menu {
  // Links within the dropdown menu
  > li > a {
    padding-bottom: 9px;
    padding-top: 9px;
  }
}

.content-header {
  padding-top: 36px;
}

.btn-primary {
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.00) 3%, rgba(0, 0, 0, 0.20) 98%);
  border: 1px solid @btn-primary-bg;
  font-size: 16px;
  line-height: ( 36 / 16 ) * 1em;
  padding: 0 ( 15 / 16 * 1em);
}

.btn-default {
  background: #fff;
  border: 1px solid @btn-primary-bg;
  color: @btn-primary-bg;
  font-size: 16px;
  line-height: ( 34 / 16 ) * 1em;
  padding: 0 ( 15 / 16 * 1em);
}

.btn-success,
.btn-warning,
.btn-danger {
  font-size: 16px;
  line-height: ( 34 / 16 ) * 1em;
  padding: 0 ( 15 / 16 * 1em);
}

.btn-group,
.input-group-btn {
  .btn-default {
    font-size: 16px;
    line-height: ( 52 / 16 ) * 1em;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.form-control {
  box-shadow: none;
  padding: 0 ( 18 / 16 * 1em );

  textarea& {
    padding-top: 9 / 16 * 1em;
    padding-bottom: 9 / 16 * 1em;
  }
}

label {
  color: #393939;
  cursor: pointer;
  //display: block;
  font-size: 16px;
  line-height: (18 / 16);
  margin: 0;
  //font-weight: normal;
  padding: 1em 0;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  line-height: (18 / 16);

  .btn {
    font-size: 16px;
    line-height: (34 / 16);
    margin: -0.5em 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}


.crm {
  .btn-group .btn-default {
    line-height: 2.25em;
  }

  .dl-horizontal {
    dt, dd {
      line-height: 18px;
      padding-bottom: 9px;
    }
  }
}

.entity-dropdown.open.open-up .entity-selector {
  bottom:0;
}

.loader__hold{
    display: flex;
    gap: 20px;
    align-items: center;

  .loader__icon{
    background-image: url('/app/svg/preloader.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    display: none;
    background-size: 100%;

    &.active{
      display: inline-block;
    }
  }
}

