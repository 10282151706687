@import "inputs/entity-dropdown";
@import "inputs/entity-selector";
@import "inputs/file-list";
@import "inputs/image-list";
@import "inputs/has-many";

.number-filter {
    .dropdown-toggle .value {
        font-weight: bold;
        margin-right: 3px;
    }
}