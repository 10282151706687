.table{
    &.table--search-results{
        tr{
            th{
                text-transform: uppercase;
                font-weight: 500;
                background-color: #F7F7F7;
                border: none;
                padding: 15px;
            }
            td{
                padding: 20px 15px;
            }
        }
    }
}