@import "@{twbs}/mixins";

// Reset
@import "@{twbs}/normalize";
@import "@{twbs}/print";

// Core CSS
@import "@{twbs}/scaffolding";
@import "@{twbs}/type";
@import "@{twbs}/code";
@import "@{twbs}/grid";
@import "@{twbs}/tables";
@import "@{twbs}/forms";
@import "@{twbs}/buttons";

// Components
@import "@{twbs}/component-animations";
@import "@{twbs}/glyphicons";

@import "@{twbs}/dropdowns";
@import "@{twbs}/button-groups";
@import "@{twbs}/input-groups";
@import "@{twbs}/navs";
@import "@{twbs}/navbar";
@import "@{twbs}/breadcrumbs";
@import "@{twbs}/pagination";
@import "@{twbs}/pager";
@import "@{twbs}/labels";
@import "@{twbs}/badges";
@import "@{twbs}/jumbotron";
@import "@{twbs}/thumbnails";
@import "@{twbs}/alerts";
@import "@{twbs}/progress-bars";
@import "@{twbs}/media";
@import "@{twbs}/list-group";
@import "@{twbs}/panels";
@import "@{twbs}/wells";
@import "@{twbs}/close";

// Components w/ JavaScript
@import "@{twbs}/modals";
@import "@{twbs}/tooltip";
@import "@{twbs}/popovers";
@import "@{twbs}/carousel";

// Utility classes
@import "@{twbs}/utilities";
@import "@{twbs}/responsive-utilities";

.input-group p.form-control {
    white-space: nowrap;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.navbar-nav > .divider {
    height: @navbar-height;
    border-left: 1px solid @navbar-default-border;
}

.well {
    padding: 10px;
}

.form-control-static > .well {
    margin-bottom: 0;
}