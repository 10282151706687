.alert{
    background-color: #EFEFEF;
    color: #393939;
    padding: 0;
    display: flex;
    align-items: center;

    &.alert--error{
        background-color: #FFD9DF;
        color: #CE374D;
    }

    &.alert--success{
        background-color: #CCF1E0;
        color: #009850;
    }

    &.alert--warning{
        background-color: #FEFCE0;
        color: #393939;

        .alert__icon{
            background-color: #FFF0DE;
        }
    }

    &.alert--info{
        background-color: #EEF9FB;
        color: #393939;
    }

    .alert__icon{
        padding: 25px;
    }

    .alert__text{
        padding: 20px;
    }

    p{
        &:last-child{
            margin-bottom: 0;
        }
    }
}