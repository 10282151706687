/**
 * ENTITY SPECIFIC
 */

.entity-page {
    height: 100%;

    .pager {
        padding: 0 @content-base-padding;
    }
}

.entity-title-buttons {
    float: left;
    padding-top: @content-header-padding;
    margin-left: 15px;
}

.entity-title {
    float: left;
    margin: 0;
    line-height: 72px;
}

.entity-search-box {
    margin-top: @content-header-padding;
}