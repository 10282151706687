/* ==========================================================================
   ENTITY FORM
   ========================================================================== */

.entity-form {
    @width: 90%;
    @footer-padding: 9px 15px;
    @footer-height: 54px;

    position: fixed;
    z-index: @zindex-navbar-fixed + 1;
    top: 0;
    right: -@width;
    right: ~"calc(-100% - 220px - 30px)";

    .size(@width, 100%);
    .size(~"calc(100% - 220px - 30px)", 100%);

    background: #fff;

    .transition(right 300ms ease-in-out);
    .box-shadow(-3px 0 0 rgba(219, 209, 224 , 0.2));

    &.opened {
        right: 0;
    }

    > footer {
        &:extend(.modal-footer);

        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: @footer-height;
        padding: @footer-padding;

        .progress {
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            margin: 0;
            width: 100%;
            height: 3px;
            border-radius: 0;
        }

        .btn {
            //font-size: 14px;
        }

        > .btn, > .btn-group {
            + .btn, + .btn-group {
                margin-left: 5px;
            }
        }

        .dropdown-toggle {
            line-height: (34 / 16);
        }

        > .btn-save {
            padding-left: 15px;
            padding-right: 15px;
        }

        .flash {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;

            font-size: @font-size-large;
            font-weight: bold;
            height: @footer-height;
            line-height: @footer-height;
            vertical-align: middle;
            padding: 0 15px;

            .opacity(0);

            .transition(opacity 200ms ease-out);

            &.show {
                .opacity(1);
            }

            &:before {
                &:extend(.glyphicon all);
                margin-right: 5px;
            }

            &-success {
                background-color: @alert-success-bg;
                color: @alert-success-text;

                &:before { content: "\e084"; }
            }

            &-danger {
                background-color: @alert-danger-bg;
                color: @alert-danger-text;

                &:before { content: "\e090"; }
            }

            &-warning {
                background-color: @alert-warning-bg;
                color: @alert-warning-text;

                &:before { content: "\e101"; }
            }
        }
    }

    .navbar {
        position: absolute;
        width: 100%;

        .container-fluid {
            padding-left: 0;
        }
    }

    &-title {
        &:extend(.modal-title);
    }

    &.dirty {
        .btn-save {
            font-weight: bold;
        }
    }

    > .tab-content {
        padding-top: @navbar-height;
        padding-bottom: @footer-height;
        height: 100%;

        > .tab-pane {
            padding: @modal-inner-padding;
            padding-left: @modal-inner-padding * 1.5;
            height: 100%;

            .field-list {
                padding: 0;
                height: auto;
                overflow: visible;
            }
        }
    }

    .field {

        > .field-label {
            display: block;
        }

        &.required > .field-label:after {
            content: '*';
            margin-left: 0.5em;
            color: red;
            font-weight: normal;
            font-size: @font-size-base;
        }

        &-help {
            margin-left: -20px;
            margin-right: 8px;
            font-size: 12px;
            color: @gray-light;
            cursor: pointer;

            &:hover {
                color: @link-hover-color;
            }
        }
    }

    .fs-deleted-message {
        display: none;
    }

    &.destroyed {

        .fs-deleted-message {
            display: block;
        }
    }
}