@import "@{twbs}/variables";

@gray-light: lighten(@gray-base, 70%);

@brand-primary: #4D1A64;

@body-bg: #f7f7f7;
@text-color: #393939;
@font-family-base: 'SofiaPro', Helvetica, Arial, sans-serif, 'Comic Sans MS';
@font-family-base-medium: 'SofiaProMedium', Helvetica, Arial, sans-serif, 'Comic Sans MS';

@font-size-base: 16px;
@line-height-base: 1.125; // 18 / 16
@link-color: #00BD64;

@padding-base-vertical:          3px;
@padding-base-horizontal:        6px;

@padding-large-vertical:         5px;
@padding-large-horizontal:       8px;

@padding-small-vertical:         1.5px;
@padding-small-horizontal:       5px;

@border-radius-base:             2px;
@border-radius-large:            5px;
@border-radius-small:            2px;

@modal-inner-padding: 20px;

@table-cell-padding:           9px 5px;
@table-condensed-cell-padding: 3px;

@navbar-default-bg: #4D1A64;
@navbar-default-link-color: #fff;
@navbar-default-link-hover-color: #00BD64;
@navbar-height:     72px;
@navbar-padding-vertical: ((@navbar-height - 38) / 2); // 36 is logo height
@nav-link-padding:  0px 10px;
@navbar-default-link-active-color: #fff;
@navbar-default-link-active-bg: #863888;

@alert-padding: 10px;

//@btn-primary-color:              #fff;
@btn-primary-bg: #00BD64;
@btn-primary-border: transparent;

@input-height-base: 54px;
@input-height-large: @input-height-base;
@input-height-small: @input-height-base;

/*
* Application variables
*/

@content-header-height: 108px;
@content-base-padding: 15px;

@content-header-padding: 18px; //((@content-header-height - @input-height-base) / 2);

/*
* Navigation Collapse breakdown
*/

@grid-float-breakpoint: 1250px;