/* ==========================================================================
   DATA GRID
   ========================================================================== */

.dg {

    > thead {
        background: #fff;

        > tr {

            > .col__button {
                width: 1px;
            }

            > .col__primary, > .col__bool {
                width: 70px;
            }

            > .col__sortable {
                background: #eee;
                cursor: pointer;

                &:after {
                    &:extend(.glyphicon);

                    font-size: @font-size-small;
                    font-weight: normal;
                    color: @gray-light;
                    padding-left: 0.5em;
                }

                &.asc:after {
                    content: "\e094";
                }

                &.desc:after {
                    content: "\e093";
                }
            }
        }
    }

    > .items {

        > .empty {
            text-align: center;
            font-style: italic;
        }

        > .item {

            > .col__auto-hide > .auto-hide-target {
                visibility: hidden;

                &.open {
                    visibility: visible;
                }
            }

            > .col__view-button {
                width: 60px;

                .dropdown-toggle {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }

            // Display buttons when hoverd
            &.active, &:hover {

                > .col__auto-hide > .auto-hide-target {
                    visibility: visible;
                }
            }

            &.active {

                > .col__view-button .btn-view {
                    cursor: not-allowed;
                    pointer-events: none; // Future-proof disabling of clicks
                    .opacity(.65);
                    .box-shadow(none);
                }

                > :first-child {
                    position: relative;

                    &:after {
                        content: '';
                        background: rgba(0, 180, 0, 0.65);
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 4px;
                        height: 100%;
                    }
                }
            }
        }
    }
}