.entity-selector {
    @item-height: 24px;

    .error {
        display: block;
        color: @alert-danger-text;
        padding: 0 @padding-base-vertical;
        text-align: center;
    }

    .search-input-container {
        padding: @padding-base-vertical;
        padding-top: 0;
    }

    .items-container {
        max-height: @item-height * 10;
        overflow-y: auto;
    }

    .items {
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-size: @font-size-small;
        line-height: 1.3;

        > .item {
            cursor: pointer;
            color: @dropdown-link-color;
            padding: 5px @padding-small-horizontal;
            padding-left: 20px;

            &:hover {
                color: @dropdown-link-hover-color;
                background: @dropdown-link-hover-bg;
            }

            &.selected:before {
                .glyphicon();

                width: 16px;
                content: "\e013";
                margin-left: -16px;
            }
        }

        > .empty, > .more {
            text-align: center;
            font-style: italic;
        }

        > .more {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.loading {

        .items > .more {
            text-indent: -9999px;
            background: url("../img/loader-bars.gif") center center no-repeat;
        }

        .btn-refresh > .glyphicon {
            .animation(loading 1s ease-in-out infinite);
        }
    }
}

@keyframes loading
{
    from { transform: rotate(0deg); }
    to   { transform: rotate(360deg); }
}

@-webkit-keyframes loading /* Safari and Chrome */
{
    from { -webkit-transform: rotate(0deg); }
    to   { -webkit-transform: rotate(360deg); }
}