/**
 * BASE LAYOUT
 */

.main-content {
    padding-top: 40px;

    .loading {
        display: block;
        text-align: center;
        font-size: @font-size-large;
        margin-top: 36px;

        &:before {
            display: inline-block;
            content: '';
            .size(16px, 11px);
            margin-right: @font-size-large;
            margin-left: -@font-size-large - 16px;
            background: url("../img/loader-bars.gif");
        }
    }
}

.main-container {
    .container-fixed();

    min-width: @container-md;
}

.column {
    float: left;

    &-main {
        width: 80%;
    }

    &-extra {
        width: 20%;

        padding-left: @content-base-padding;
        padding-right: @content-base-padding;
    }
}

.content-header {
    overflow: hidden;

    height: @content-header-height;

    > .column-main {
        padding-left: @content-base-padding;
    }

    background-color: #fff;

    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    position: relative;
}

.content-body {
    height: 100%;

    margin-top: -@content-header-height;
    padding-top: @content-header-height;
    // padding-left: @content-base-padding;

    .column {
        overflow: auto;
        height: 100%;
    }

    .column-extra {
        background-color: #fff;
        position: relative;
    }
}

.limit-height {
    overflow-y: auto;
    max-height: 250px;
}

.cp {
    &-container {
        background: #fff;
        padding: (@grid-gutter-width / 2);

        > .page-header {
            margin-top: 0;
        }
    }
}

div.tab-pane {
    fieldset {
        margin-top:25px;
    }
}

.has-many-item-view {

    div.tab-pane {
        fieldset {
            margin-top:0;
        }
    }

    &.open {
        outline:solid 3px #0000ee;
        padding-bottom:50px;

        div.field__inline-relation {
            margin-top:30px;
            margin-bottom:30px;

            .field-label {
                margin-bottom:20px;
            }
        }

        .has-many-item-view {

            &.open {
                border-bottom:solid 2px #0000ee;
                border-top:solid 2px #0000ee;
                outline:none;
                padding-bottom:30px;
                padding-top:8px;

                div.tab-pane {
                    fieldset {
                        margin-bottom:30px;
                    }
                }

                h4 {
                    border-bottom:solid 1px #666;
                    margin-bottom:20px;
                    padding-bottom:20px;
                }
            }

            div.tab-pane {
                fieldset {
                    margin-bottom:0;
                }
            }

            h4 {
                border-bottom:none;
                margin-bottom:9px;
                padding-bottom:0;
            }
        }

        div.tab-pane {
            fieldset {
                margin-bottom:60px;
            }
        }

        h4 {
            border-bottom:solid 1px #666;
            color:#0000ee;
            margin-bottom:20px;
            padding-bottom:20px;
        }
    }
}