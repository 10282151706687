.nav-tabs{
    &.nav-tabs--v2{
        background-color: #F7F7F7;
        border-radius: 5px;
        padding: 10px 10px;
        margin-bottom: 2rem;
        display: flex;
        gap: 40px;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 2px 2px rgba(0,0,0,0.1);
        border-bottom: none;

        .nav-tabs__holder {
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;

            .nav-tabs__menu-item{
                text-align: center;
                list-style: none;
                
                .nav-tabs__menu-item__link{
                    color: #393939;
                    font-size: 14px;
                    text-decoration: none;
                    padding: 8px 25px 8px;
                    border-radius: 5px;
                    display: block;
                    outline: none;

                    &.nav-tabs__menu-item__link--active{
                        box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
                        background-color: #fff;
                        color: #6C2D6D;

                        @media (hover: hover) {
                            &:hover{
                                color: #6C2D6D;
                                background-color: #fff;
                            }
                        }
                    }
                }
                
                @media (hover: hover) {
                    .nav-tabs__menu-item__link:hover{
                        color: #863888;
                        background-color: #f0f0f0;
                    }
                }
            }

        }

        .nav-tabs__agent-selector{
            .nav-tabs__agent-selector__input{
                background: #fff;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 5px 10px;

                @media (hover: hover) {
                    &:hover{
                        background-color: #f1ecf4;
                    }
                }
            }
        }

    }
}