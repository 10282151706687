.btn{
    &.btn--large{
        padding: 9px 20px;
        border-radius: 5px;
    }

    &.disabled, [disabled]{
        pointer-events: not-allowed;
        background-color:#ccc !important;
        border-color:#333 !important;
    }
}